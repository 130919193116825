/* eslint-disable tailwindcss/no-custom-classname */
import { useMedia } from 'react-use';
import twMerge from 'twMerge';

import sectionStyles, { sectionWrapper } from 'molecules/Section/Section.styles';

import ContentfulEditWidget from 'components/ContentfulEditWidget';

import screens from 'theme/spacing/screens';

import type { VariantProps } from 'class-variance-authority';
import type { ComponentPropsWithoutRef, ElementType, FC } from 'react';

import 'molecules/Section/section.css';

export type backgroundType = VariantProps<typeof sectionStyles>['background'];

export interface SectionProps
  extends ComponentPropsWithoutRef<ElementType>,
    VariantProps<typeof sectionStyles>,
    VariantProps<typeof sectionWrapper> {
  sectionAs?: ElementType;
  contentfulId?: string;
}

const Section: FC<SectionProps> = ({
  contentfulId,
  id,
  padding = 'large',
  background = 'white',
  contained = true,
  fullWidth = false,
  sectionAs = 'section',
  containerClass,
  className = '',
  children,
  ...props
}) => {
  const isDesktop = useMedia(`(max-width: ${screens.xl})`, false);
  const SectionComponent = sectionAs;
  const anchorTop = isDesktop ? '-100px' : '-150px';

  return (
    <SectionComponent className={twMerge(sectionStyles({ background }), className)} {...props}>
      {contentfulId && <ContentfulEditWidget id={contentfulId} />}
      <div className="anchor-element" style={{ top: anchorTop }} id={id} />
      <div className={`${twMerge(sectionWrapper({ padding, contained, fullWidth }))} ${containerClass || ''}`}>
        {children}
      </div>
    </SectionComponent>
  );
};

export default Section;
