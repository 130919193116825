import { useState } from 'react';
import twMerge from 'twMerge';

import buttonVariants, { iconSizes, iconVariants, underlineVariants } from 'molecules/Button/Button.styles';
import Icon, { type IconIds } from 'molecules/Icon';

import Modal from 'components/Modal';

import parseUrl from 'utils/parseUrl';

import type { VariantProps } from 'class-variance-authority';
import type { HeaderProps } from 'components/Header';
import type { ContentfulComponentButton, ContentfulComponentModal } from 'graphqlTypes';
import type { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

type NativeButtonProps = ComponentPropsWithoutRef<'button'> & ComponentPropsWithoutRef<'a'>;

export interface ButtonProps
  extends Omit<NativeButtonProps, 'href' | 'id'>,
    VariantProps<typeof buttonVariants>,
    Partial<Pick<ContentfulComponentButton, 'href' | 'id' | 'internalName'>> {
  children?: ReactNode;
  startIcon?: IconIds | null;
  endIcon?: IconIds | null;
  iconColor?: VariantProps<typeof iconVariants>['color'];
  label?: string | null;
  modal?: ContentfulComponentModal;
  isFullWidth?: boolean;
  signInOptions?: HeaderProps['signInOptions'];
  styles?: string;
  demoCta?: boolean | null;
}

const Button: FC<ButtonProps> = ({
  children,
  demoCta,
  disabled,
  label,
  size = 'md',
  hierarchy = 'primary',
  href,
  startIcon,
  endIcon,
  iconColor,
  noPadding,
  modal,
  styles,
  isFullWidth,
  signInOptions,
  ...props
}) => {
  const hasModal = !!modal;
  const [isOpen, setIsOpen] = useState(false);
  const { Component: as, ...urlProps } = parseUrl(href?.href || '/');
  const Component = !href || as === 'div' || hasModal ? 'button' : 'a';
  const iconClass = twMerge(iconVariants({ color: iconColor, hierarchy }));
  const iconSize = iconSizes[size || 'md'];
  const iconOnly = !children && !label && (!!startIcon || !!endIcon);

  const handleClick = (e: React.MouseEvent) => {
    if (hasModal) {
      setIsOpen(true);

      return;
    }

    if (href?.href?.startsWith('#')) {
      e.preventDefault();
      const targetId = href.href;
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        const scrollPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;

        window.scroll({
          top: scrollPosition,
          behavior: 'smooth',
        });
        window.location.hash = href?.href;
      }
    }
  };

  const hasUnderline =
    hierarchy && ['primary', 'primaryDark', 'secondary', 'secondaryDark', 'tertiary'].includes(hierarchy);

  return (
    <Component
      className={`${twMerge(buttonVariants({ hierarchy, size: size || 'md', iconOnly, noPadding, isFullWidth }))} ${styles} ${demoCta ? 'demo-btn' : ''}`}
      onClick={e => handleClick(e)}
      {...(Component === 'button' && { disabled })}
      {...urlProps}
      {...props}
    >
      {startIcon && <Icon icon={startIcon} size={iconSize} className={iconClass} aria-hidden={true} />}
      {children}
      {label}
      {hasModal && <Modal isOpen={isOpen} setIsOpen={setIsOpen} signInOptions={signInOptions} {...modal} />}
      {endIcon && <Icon icon={endIcon} size={iconSize} className={iconClass} aria-hidden={true} />}
      {hasUnderline && <div className={twMerge(underlineVariants({ hierarchy: disabled ? null : hierarchy }))} />}
    </Component>
  );
};

export default Button;
