import { useEffect, useState } from 'react';

import Modal from 'components/Modal';

const GlobalModalForm = () => {
  const [isModalOpen, toggleIsModalOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState({});

  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    window.toggleModal = (heading: string, subhead: string, formId: string) => {
      setIsHidden(false);
      setModalFormData({
        formId,
        heading,
        subhead,
      });
      toggleIsModalOpen(true);
    };

    return () => {
      window.toggleModal = null;
    };
  }, []);

  return isHidden ? null : <Modal isOpen={isModalOpen} setIsOpen={toggleIsModalOpen} {...modalFormData} />;
};

export default GlobalModalForm;
