import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Icon from 'molecules/Icon';

type ContentfulEditWidgetProps = {
  id?: string;
  type?: string;
  className?: string;
};

const ContentfulEditWidget = ({ className, id, type }: ContentfulEditWidgetProps) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const checkEditable = () => {
      const cookie = Cookies.get('editable');
      setIsActive(cookie === 'true');
    };

    checkEditable();

    window.addEventListener('editableChange', checkEditable);

    return () => {
      window.removeEventListener('editableChange', checkEditable);
    };
  }, []);

  const handleExitClick = () => {
    Cookies.set('editable', 'false');
    setIsActive(false);
    window.dispatchEvent(new Event('editableChange'));
  };

  if (!isActive) {
    return null;
  }

  return (
    <>
      <Link to={`https://app.contentful.com/spaces/3cnw7q4l5405/environments/master/entries/${id}`} target="_blank">
        <div
          className={twMerge(
            'scale fixed right-[40px] z-[9999] flex h-[34px] items-center justify-between rounded-[6px] px-[10px] text-white bg-gradient-to-r from-green-500 to-green-400',
            type === 'page' ? 'w-[105px] top-[10px]' : 'w-[32px] top-[20px]',
            className,
          )}
        >
          {type === 'page' && <p className="text-sm">Edit Page</p>}

          <Icon icon="edit" className="-mr-1 fill-white" size={16} aria-hidden={true} />
        </div>
      </Link>

      {type === 'page' && (
        <button
          onClick={handleExitClick}
          className={twMerge(
            'scale fixed left-[40px] z-[9999] top-[10px] flex h-[34px] items-center justify-between rounded-[6px] px-[10px] text-white bg-gradient-to-r from-blue-500 to-blue-400 w-[65px]',
            className,
          )}
        >
          <p className="text-sm">Exit</p>
          <Icon icon="x" className="-mr-1 fill-white" size={16} aria-hidden={true} />
        </button>
      )}
    </>
  );
};

export default ContentfulEditWidget;
