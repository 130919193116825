import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Children, isValidElement } from 'react';
import twMerge from 'twMerge';

import 'utils/richTextParser/richTextStyles.css';

import Button from 'molecules/Button';
import Image from 'molecules/Image';

import ComponentHeading from 'components/Heading';
import HubspotCta from 'components/Hubspot/HubspotCta';
import HubspotVideo from 'components/Hubspot/HubspotVideo';
import MediaAsset from 'components/MediaAsset';

import parseUrl from 'utils/parseUrl';
import richTextContent, { headingStyles, linkStyles, listStyles } from 'utils/richTextParser/richTextStyles';

import type { CommonNode } from '@contentful/rich-text-react-renderer';
import type { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import type { ReactNodeArray } from 'prop-types';
import type { ReactElement, ReactNode } from 'react';
import type { themeType } from 'utils/getTheme';

const extractStringContent = (children: ReactNode): string => {
  let result = '';
  Children.forEach(children, child => {
    if (typeof child === 'string') {
      result += child;
    } else if (isValidElement(child) && child.props.children) {
      result += extractStringContent(child.props.children);
    }
  });

  return result;
};

const richTextParser = (
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>,
  wordCount?: number,
  theme?: themeType,
) => {
  if (!content) {
    return null;
  }

  const OL = 'ol';
  const UL = 'ul';

  theme = theme || 'light';

  const renderText = (text: ReactNodeArray): ReactNode => {
    if (typeof text !== 'string') {
      return text;
    }

    let currentWords = 0;
    const result: (string | ReactElement)[] = [];

    const ctaRegex = /\{\{cta\('([a-zA-Z0-9-]+)'(?:, *'([a-zA-Z0-9-]+)')?\)\}\}/g;
    const videoRegex = /\{% video_player "embed_player" [^%]*player_id='([^']+)'.*?%\}/g;

    const combinedRegex = new RegExp(`(${ctaRegex.source})|(${videoRegex.source})`, 'g');
    const parts = text.split(combinedRegex);

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (!part) {
        continue;
      }

      if (ctaRegex.test(part)) {
        const ctaId = parts[i + 1];
        result.push(<HubspotCta hubspotCtaId={ctaId} />);
        i += 2;
      } else if (videoRegex.test(part)) {
        const playerId = parts[i + 1];
        result.push(<HubspotVideo playerId={playerId} />);
        i += 2;
      } else {
        if (wordCount) {
          const words = part.split(' ');
          const remainingWords = wordCount - currentWords;
          if (remainingWords <= 0) {
            break;
          }
          currentWords += words.length;
          if (currentWords > wordCount) {
            const truncatedWords = words.slice(0, remainingWords);
            result.push(truncatedWords.join(' ') + '...');
            break;
          }
        }
        result.push(part);
      }
    }

    return result.length === 1 ? result[0] : result;
  };

  const options = {
    renderText,
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: CommonNode, children: ReactNode) => {
        // eslint-disable-next-line react/destructuring-assignment
        if (children?.length < 2 && (children?.length === 0 || !children?.[0])) {
          return null;
        }

        const noValue = children?.length === 1 && children?.[0].length === 0;

        if (!noValue) {
          return <p className={twMerge(richTextContent({ theme }))}>{children}</p>;
        } else {
          return null;
        }
      },
      [BLOCKS.HEADING_1]: (node: CommonNode, children: ReactNode) => (
        <h1 className={twMerge(headingStyles({ type: 'h1', theme }))}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node: CommonNode, children: ReactNode) => {
        const text = extractStringContent(children);
        const id = text.toLowerCase().trim().replace(/\s+/g, '-').replace('?', '').replace(':', '');

        return (
          <>
            {id && <div id={id} className="invisible scroll-mt-[85px] md:scroll-mt-[160px]" />}
            <h2 className={twMerge(headingStyles({ type: 'h2', theme }))}>{children}</h2>
          </>
        );
      },
      [BLOCKS.HEADING_3]: (node: CommonNode, children: ReactNode) => (
        <h3 className={twMerge(headingStyles({ type: 'h3', theme }))}>{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node: CommonNode, children: ReactNode) => (
        <h4 className={twMerge(headingStyles({ type: 'h4', theme }))}>{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node: CommonNode, children: ReactNode) => (
        <h5 className={twMerge(headingStyles({ type: 'h5', theme }))}>{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node: CommonNode, children: ReactNode) => (
        <h6 className={twMerge(headingStyles({ type: 'h6', theme }))}>{children}</h6>
      ),
      [BLOCKS.OL_LIST]: (node: CommonNode, children: ReactNode) => (
        <OL className={twMerge(listStyles())}>{Children.map(children, child => child)}</OL>
      ),
      [BLOCKS.UL_LIST]: (node: CommonNode, children: ReactNode) => (
        <UL className={twMerge(listStyles())}>
          {Children.map(children, child => (
            <>{child}</>
          ))}
        </UL>
      ),
      [INLINES.HYPERLINK]: (node: CommonNode, children: ReactNode) => {
        const { data } = node;
        const { Component: as, ...urlProps } = parseUrl(data?.uri || '/');
        const Component = as === 'div' ? 'button' : 'a';

        return (
          <Component {...urlProps} className={twMerge(linkStyles(), richTextContent({ theme }))}>
            {children}
          </Component>
        );
      },
      [BLOCKS.TABLE_ROW]: (node: CommonNode, children: ReactNode) => (
        <tr className="odd:bg-white even:bg-gray-50">{children}</tr>
      ),
      [BLOCKS.TABLE]: (node: CommonNode, children: ReactNode) => (
        <table className="block overflow-auto">{children}</table>
      ),
      [BLOCKS.TABLE_CELL]: (node: CommonNode, children: ReactNode) => (
        <td className="border border-gray-100  px-3 py-2">{children}</td>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (node: CommonNode, children: ReactNode) => <th className="px-3 py-2">{children}</th>,
      [BLOCKS.EMBEDDED_ENTRY]: (node: CommonNode) => {
        const {
          data: { target },
        } = node;
        if (!target) {
          return null;
        }
        switch (target.__typename) {
          case 'ContentfulComponentButton':
            return <Button {...target} />;
          case 'ContentfulComponentHeading':
            return (
              <ComponentHeading
                {...target}
                sectionIdLink={target.sys.id}
                className="[&>div>div]:gap-0 [&_h2]:!my-4 [&_ul>li>last:p]:pb-0 [&_ul]:mb-0"
              />
            );
          case 'ContentfulItemMediaAsset':
            return (
              <MediaAsset className={`${target?.youtubeEmbedLink ? 'aspect-video ' : ''}mb-6`} {...target} />
            );

          default:
            return null;
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: CommonNode) => {
        const { data } = node;
        if (data.target.file.contentType.includes('video')) {
          const image = { file: data.target.file, contentful_id: data.target.contentful_id };

          return <MediaAsset className={`${data.target?.youtubeEmbedLink ? 'aspect-video ' : ''}mb-6`} image={image} />;
        }

        return (
          <div className="mb-6">
            <Image image={data.target} alt={data.target.title} />
          </div>
        );
      },
    },
  };

  return renderRichText(content, options) as ReactElement[];
};

export type RichText = RenderRichTextData<ContentfulRichTextGatsbyReference>;

export default richTextParser;
